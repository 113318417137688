import React from 'react'
import TextSegment from '../textsegment'
import Title from '../title'

export default function TherapieSection() {


    return (
        <>
            <div id='therapie' className="subheading"></div>
            <Title name="Therapie en visie"/>
            <div className='text'>
                <p>
                    Doorheen ons leven botsen we vaak op uitdagingen of moeilijkheden die ons dagelijks leven op
                    verschillende domeinen (familiaal, relationeel, professioneel, lichamelijk, …) sterk kunnen
                    beïnvloeden. Soms gebeurt het dat we de weg kwijt geraken of het gevoel hebben vast komen te
                    zitten. <strong>Op zo’n momenten kan psychologische ondersteuning nodig of wenselijk zijn. </strong>
                </p>
                <p>
                    Als therapeut hecht ik enorm veel waarde aan de mens achter de problematiek. Geen enkel verhaal is
                    hetzelfde, daarom is therapie <strong>op maat van de cliënt </strong> voor mij enorm belangrijk.
                </p>
                <p>
                    Therapie betekent voor mij samen op avontuur gaan, uit te zoeken wat jij op dit moment nodig hebt.
                    Hierbij is de cliënt de expert van zichzelf, en de therapeut de expert van het proces. De ‘klik’
                    tussen cliënt en therapeut is hierbij van essentieel belang!
                </p>
                <p>
                    Ik werk vanuit het kader van de interactionele vormgeving. Deze therapievorm gaat uit van een nieuw
                    model waarbij verschillende therapeutische stromingen (psychosynthese, Gestalttherapie,
                    systeemdenken, contextuele therapie, cliëntgerichte, …) met elkaar in interactie treden. Deze visie
                    ondersteunt het werken vanuit <strong>de uniciteit en hulpvraag van iedere cliënt.</strong>
                </p>
            </div>
        </>
    )
}
