import React from 'react'
import Title from '../title';
import TextSegment from '../textsegment';

export default function TarievenSection() {
    
    return (
    <>
        <div id='tarieven' className="subheading"></div>
        <Title name="Tarieven" />
        <div className='text'>
        <p>
          <div className='listbox'>
          <ul className='list'>
          <li>Gesprek in de praktijk: € 60</li>
          <li>Online gesprek: € 60</li>
          <li>Gesprek aan huis (binnen een straal van 10 km): € 70</li>
          </ul>
          </div>

Een sessie duurt 50 (+10) minuten. 
Betaling is mogelijk via QR-code, contant of via tijdige overschrijving (let op: geen bancontact). Verschillende mutualiteiten voorzien gedeeltelijke terugbetaling. Ik raad u aan uw mutualiteit te raadplegen voor meer informatie.
</p>
<p>
<b> Gelieve 24 uur op voorhand telefonisch te verwittigen indien de afspraak niet kan doorgaan. Bij laattijdige of geen verwittiging zal de sessie worden aangerekend.</b>
</p>
</div>
    </>
  )
}

